'use client'

import Logout from '@/app/_components/Header/Logout'
import ChevronDown from '@/app/_components/Icon/ChevronDown'
import InuptIcon from '@/app/_components/Icon/Inupt'
import PopupNotMobileGms from '@/app/_messenger/popup/PopupNotMobileGms'
import { useI18n } from '@/locales/client'
import Link from 'next/link'
import { useState } from 'react'

export default function MobileHeaderLink({
  isLoggedIn,
}: {
  isLoggedIn: boolean
}) {
  const t = useI18n()
  // const [isOpen, setIsOpen] = useState(false)
  const [isSolutionOpen, setIsSolutionOpen] = useState(false)
  // const session = useSession()
  // const userType = session.data?.user.type
  // const collaboBlockUser = !Boolean(
  //   userType === 'admin' || userType === 'agent',
  // )
  return (
    <div className="my-32 flex flex-col gap-8 px-20 text-white">
      <Link href="/characters">
        <span className="c-b3">탐색</span>
      </Link>

      <Link href="/collabo">
        <span className="c-b3">콜라보</span>
      </Link>

      {/* {collaboBlockUser ? (
        <Link href="/collabo">
          <span className="c-b3">파트너 찾기</span>
        </Link>
      ) : (
        <div
          className="flex flex-col"
          onClick={() => setIsOpen((prev) => !prev)}>
          <div className="c-b3 flex list-none items-center gap-4">
            
            <span className={isOpen ? 'text-butterscotch' : ''}>
              파트너 찾기
            </span>
            <div className={`scale-50 ${isOpen ? 'rotate-180' : 'rotate-0'}`}>
              <ChevronDown fill={isOpen ? '#fdcc53' : 'white'} />
            </div>
          </div>
          <div
            className={`${isOpen ? 'block' : 'hidden'} flex flex-col gap-8 px-8 pt-8`}>
            <Link href="/collabo">
              <span className="c-b3 hover:underline">프로젝트</span>
            </Link>
            <Link href="/collabo/create">
              <span className="c-b3 hover:underline">프로젝트 등록하기</span>
            </Link>
          </div>
        </div>
      )} */}

      <Link href="https://trend.inabooth.io/" target="_blank">
        <span className="c-b3">{t('common.trendLabel')}</span>
      </Link>
      <div
        className="flex flex-col"
        onClick={() => setIsSolutionOpen((prev) => !prev)}>
        <div className="c-b3 flex list-none items-center gap-4">
          {/* <span>{t('common.licensingLabel')}</span> */}
          <span className={isSolutionOpen ? 'text-butterscotch' : ''}>
            솔루션
          </span>
          <div
            className={`scale-50 ${isSolutionOpen ? 'rotate-180' : 'rotate-0'}`}>
            <ChevronDown fill={isSolutionOpen ? '#fdcc53' : 'white'} />
          </div>
        </div>
        <div
          className={`${isSolutionOpen ? 'block' : 'hidden'} flex flex-col gap-8 px-8 pt-8`}>
          <Link href="https://inupt.io/" target="_blank">
            <span className="c-b3 flex items-center gap-3 hover:underline">
              굿즈 제작 <InuptIcon />
            </span>
          </Link>
          <PopupNotMobileGms />
        </div>
      </div>

      {isLoggedIn && <Logout />}
    </div>
  )
}
