import { ReactNode, useEffect, useRef, useState } from 'react'
import { twMerge } from 'tailwind-merge'

export type Options = {
  id: string
  content: any
}

type Props = {
  children: ReactNode
  options: Options[]
  className?: string
  onSelect?: any
  selected: Options
}

function doesContain(target: HTMLElement, container: HTMLElement) {
  if (target === null) return false

  if (target.parentNode === container) {
    return true
  }

  return doesContain(target.parentNode as HTMLElement, container)
}

export default function HeaderLanguageDropdown({
  children,
  options,
  className,
  selected,
  onSelect,
}: Props) {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const ref = useRef<HTMLDivElement>(null)
  const buttonRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handleClickOutside: EventListenerOrEventListenerObject = (
      e: Event,
    ) => {
      if (
        isOpen &&
        ref.current &&
        !doesContain(e.target as HTMLElement, ref.current) &&
        buttonRef.current !== e.target
      ) {
        setIsOpen(false)
      }
    }

    document.addEventListener('click', handleClickOutside, true)

    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  }, [isOpen])

  const toggleDropdown = () => {
    setIsOpen(!isOpen)
  }
  return (
    <div className={`relative h-42 `}>
      <div
        aria-hidden
        ref={buttonRef}
        onClick={toggleDropdown}
        className={twMerge(
          'text-16 flex text-white font-pretendard items-center cursor-pointer',
          `${className}`,
        )}>
        {selected ? (
          <div className="py-12 [&_img]:border-none">{selected.content}</div>
        ) : (
          children
        )}

        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="size-24"
            viewBox="0 0 24 25"
            fill="none">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M9.11716 11.6087C9.27337 11.4638 9.52663 11.4638 9.68284 11.6087L12 13.758L14.3172 11.6087C14.4734 11.4638 14.7266 11.4638 14.8828 11.6087C15.0391 11.7536 15.0391 11.9885 14.8828 12.1334L12.5657 14.2827C12.2533 14.5724 11.7467 14.5724 11.4343 14.2827L9.11716 12.1334C8.96095 11.9885 8.96095 11.7536 9.11716 11.6087Z"
              fill="white"
            />
          </svg>
        </div>
      </div>
      <div
        ref={ref}
        className={`relative z-10 mt-4 w-85 overflow-y-auto rounded-lg bg-white ${
          isOpen ? `p-8 xl:shadow-md` : 'max-h-0'
        }`}>
        {options.map((item) => (
          <div
            aria-hidden
            className="text-primary cursor-pointer rounded-xl p-8 hover:bg-french-bleu/10"
            id={String(item.id)}
            onClick={() => {
              onSelect(item)
            }}
            key={item.id}>
            {item.content}
          </div>
        ))}
      </div>
    </div>
  )
}
